@font-face {
  font-family: 'RobotoMono';
  src: url('../../fonts/RobotoMono/RobotoMono-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'RobotoMono';
  src: url('../../fonts/RobotoMono/RobotoMono-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

.contribution-map-container {
  width: 80%; /* Keep the width consistent with other components */
  margin: 0 auto; /* Center the entire container */
  padding: 20px 0;
  color: #fff;
  font-family: 'RobotoMono', sans-serif;

  .contribution-map {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center all content except the header */
    color: #fff;

    h2 {
      font-size: 2em;
      margin-bottom: 20px;
      color: #fff;
      font-family: 'RobotoMono', sans-serif;
      text-align: left;
      align-self: flex-start; /* Ensure the header stays aligned to the left */
      width: 100%;
    }

    .react-github-calendar {
      width: 100%; /* Make the contribution graph take up full width */
      display: flex;
      justify-content: center; /* Center the contribution graph itself */
      overflow-x: auto; /* Enable horizontal scrolling if content overflows */
    }

    .calendar-wrapper {
      width: 100%; /* The graph wrapper takes the full width */
      display: flex;
      justify-content: center; /* Center the contents inside the wrapper */
    }
  }
}
